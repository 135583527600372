import './App.scss';
import React, {useState, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavBar from './Components/Navbar/NavBar';
import Hero from './Components/Hero/Hero';
import AboutMe from './Components/AboutMe/AboutMe';
import Portfolio from './Components/Potfolio/Portfolio';
import Experience from './Components/Experience/Experience';
import Contact from './Components/Contact/Contact';
import Footer from './Components/Footer/Footer';

function Content() {
    const [CursorX, setCursorX] = useState();
    const [CursorY, setCursorY] = useState();

    window.addEventListener('mousemove',(e) => {
        setCursorX(e.pageX);
        setCursorY(e.pageY);
    });

    window.addEventListener('click',() => {
        document.querySelector('.cursor').classList.add('expand');

        setTimeout(() => {
            document.querySelector('.cursor').classList.remove('expand');
        }, 500);

    });

    return (
        <div>
     {/* <div style={{marginTop:6+"rem"}}></div> */}
      <Hero />
      <Portfolio />
      <AboutMe />
      {/* <Experience /> */}
      <Contact />
      <Footer />

      <div className="cursor"
        style={{
            left: (CursorX - 10) + 'px',
            top: (CursorY - 10) + 'px'
        }}
      ></div>
        </div>
    )
}

export default Content
