import React,{useEffect} from 'react'
import './Contact.css'
import { Container, Jumbotron } from 'react-bootstrap'
import AOS from "aos";
import "aos/dist/aos.css";
import LinkedIN from '../AboutMe/LinkedIN.svg'
import Mail from '../AboutMe/Mail.svg'
import Twitter from '../AboutMe/Twitter.svg'
import Instagram from '../AboutMe/Instagram.svg'
import WhatsApp from '../AboutMe/WhatsApp.svg'

function Contact() {
    useEffect(() => {
        AOS.init({
          duration : 500
        });
      }, []);
    return (
        <section classNameName="contactArea" id="contactArea">
            <Container>
                <h2 className="contactHeader">Got something to talk about? <span className="outline-text"><br></br>Let's Talk...</span> </h2>
                {/* <h4 className="mt-5">Let's Talk...</h4> */}
                <p className="social">
                <a href="https://www.linkedin.com/in/harsh-kothari-b1820767" target="_blank"><img src={LinkedIN} alt="LinkedIN account of Harsh Kothari" className="ih img-fluid"></img></a>
                <a href="mailto:harshkothari17@gmail.com" target="_blank"><img src={Mail} alt="Email of Harsh Kothari" className="ih img-fluid ml-3 mr-3"></img></a>
                <a href="https://twitter.com/_harshkothari_" target="_blank"><img src={Twitter} alt="Twitter of Harsh Kothari" className="ih img-fluid"></img></a>
                <a href="https://instagram.com/_harshkothari_" target="_blank"><img src={Instagram} alt="Instagram of Harsh Kothari" className="ih img-fluid ml-3 mr-3"></img></a>
                <a href="https://api.whatsapp.com/send?phone=919967686901" target="_blank"><img src={WhatsApp} alt="Whatsapp of Harsh Kothari" className="ih img-fluid"></img></a>
                                
                </p>
                {/* <p>
                    <Button variant="primary">Learn more</Button>
                </p> */}
            </Container>
        </section>
    )
}

export default Contact
