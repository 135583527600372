import React, {useState, useEffect } from 'react'
import {Navbar, Nav} from 'react-bootstrap';
import './Navbar.css';
import { Link } from "react-scroll";
import Logo from "./logo.svg";
import resume from '../AboutMe/pdfs/HarshKothariResume.pdf'
import About from '../AboutMe/AboutMe.js'

const NavBar = () => {
    const [show, setShow] = useState(true)

    const controlNav = () =>{
        if(window.scrollY > 100){
            setShow(false)
        }
        else{
            setShow(true)
        }
    }


    // useEffect(() => {
    //     document.addEventListener("wheel", function(event){

    //         if(window.scrollY > 1080) {
    //           // we are going down
    //           document.getElementById('navbar').style.border = 'none';
                
    //         }
         
    //         else {
    //           // we are going up
    //           document.getElementById('navbar').style.border = 'block';
    //         }
    //      })
    // }, [])


    useEffect(() => {
        document.addEventListener("wheel", function(event){

            if(event.deltaY > 100) {
              // we are going down
              document.getElementById('navbar').style.opacity = '0';
            }
         
            else {
              // we are going up
              document.getElementById('navbar').style.opacity = '1';
            //   document.getElementById('navbar').style.backgroundColor = '#f6f6f6';
            //   document.getElementById('navbar').style.paddingTop = '0.2rem';
            //   document.getElementById('navbar').style.paddingBottom = '0.3rem';
            //   document.getElementById('navs').style.color = '#fff';
            }
         })
    }, []);

    return (
        <Navbar expand="lg" fixed="top" id="navbar">
        <Navbar.Brand href="#home" className="navBrand" >
        <Link
                    to="heroArea"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                >
            {/* Harsh Kothari<span className="dot">.</span> */}
            <img src={Logo} className="img-fluid logo-image" id="logo_img"></img>
                {/* HK<span className="dot-hero">.</span> */}
                </Link></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
            <div className="mx-auto"></div>
        <Nav className="navLink">
            {/* <Nav.Link className="navs" id="navs">
                <Link
                    activeClass = "active"
                    to="heroArea"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                >
                    Home
                </Link>
            </Nav.Link> */}
            {/* <Nav.Link className="navs" id="navs">
            <Link
                    activeClass = "active"
                    to="aboutMeArea"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                >
                    About Me
                </Link>
            </Nav.Link> */}
            <Nav.Link className="navs" id="navs">
                <Link
                        activeClass = "active"
                        to="portfolioArea"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                    >
                        Portfolio
                </Link>
            </Nav.Link>
            <Nav.Link className="navs" id="navs">
            <Link
                        activeClass = "active"
                        to="aboutMeArea"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                    >
                        About Me
                </Link>
            </Nav.Link>
            <Nav.Link className="navs" id="navs" target="_blank" href="https://drive.google.com/file/d/1QNcqGwxQigMmccU6Cnjq5o0DcXPjNm25/view?usp=sharing">Resume</Nav.Link>

            <Nav.Link className="navs" id="navs" target="_blank" href="https://toharshk.medium.com/">Blog</Nav.Link>
            <Nav.Link className="navs" id="navs">
            <Link
                        activeClass = "active"
                        to="contactArea"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                    >
                        Contact Me
                </Link>
            </Nav.Link>
        </Nav>
       
        </Navbar.Collapse>
    </Navbar>
    )
}

export default NavBar
