import React from 'react';
import { Button, Container, Row } from 'react-bootstrap';
import Typewriter from "typewriter-effect";
import './Hero.css';
import { Link } from "react-scroll";
import NavBar from '../Navbar/NavBar.js';
import LinkedIN from '../AboutMe/LinkedIN.svg'
import Mail from '../AboutMe/Mail.svg'
import Twitter from '../AboutMe/Twitter.svg'
import Download from './download.svg'
import heroImg from './heroImage4.jpg'
import resume from '../AboutMe/pdfs/HarshKothariResume.pdf'

function Hero() {
    return (
        <div>
            
    <NavBar />
        {/* <div className="screen"></div> */}
        <section id="heroArea">
            <Container>
            {/* <img src={scrollDown} alt="scroll down" className="scroll-down"/> */}
                <Container>
                    <Row className="heroRow">
                        {/* <Col className="leftCol" lg={7}> */}
                        <div className="img-col-hero">
                            
                        <img src={heroImg} className="img-fluid hero-small-img"></img>
                        <div className="hero-text-col">
                        <p className="topSubheader">
                        
                        I'm Harsh Kothari
                        <span className='mr-2'></span> 
                        {/* <Typewriter
                        options={{loop:true,}}
                        onInit={(typewriter)=> {

                        typewriter
                        
                        .typeString(" Product Designer")
                            
                        .pauseFor(500)
                        .deleteAll()
                        .start()
                        .typeString(" Web3 Designer")
                            
                        .pauseFor(500)
                        .deleteAll()
                        .start();
                        }}
                        /> */}
                        </p>
                    {/* <h1 className="heroHeader">Harsh Kothari</h1> */}
                    <p className="heroHeader">
                     <span className='header-sub'>

                        <span></span> Product Designer <a href="https://unacademy.com/" target="_blank" className='current-company'>@<span className='un-color'>Un</span><span className='academy-color'>academy</span></a>
                        {/* <span></span> | ex - <a href="https://www.cohesive.so/" target="_blank" className="cohesive-link">@Cohesive</a> , <a href="https://www.linkedin.com/company/tekion/" target="_blank" className="organiser-link l-1"> @Tekion Corp </a>,  <a href="https://www.linkedin.com/company/nobroker-in/" target="_blank" className="organiser-link l-2">@NoBroker.com</a><span className="red"> & </span> <a href="https://www.cse.iitb.ac.in/" target="_blank" className="organiser-link l-3"> @IIT-Bom</a> */}
                        <span></span> | <a href="https://harshkothari.framer.website/" target="_blank" className='current-company'>more... ↗</a>
                        </span> 

                    </p>
                        </div>
                        </div>
                        
                                <p className="heroHeader">
                                
                                {/* | Fullstack Developer */}
                                 {/* I am a product designer passionate about creating meaningful user experiences through minimal design. Always looking to grow and work with diverse teams. Specializing in research, ideation, and visual design. A coffee lover who enjoys bringing people together in the digital and physical worlds. */}
                                 {/* I'm a Product Designer on a mission to craft journeys that make users and businesses go 'Wow!'<br></br>Let's create something amazing together! */}
                                 I'm a 'I can-do-it' Product Designer, who is great at convincing PMs and creative designers to get things done! Always looking to grow and work with diverse teams. Specialising in research, ideation, and flow design, I'm also focusing on conversion-growth design. A coffee lover who uses storytelling for growth.
                                 </p>

                                <a href="https://drive.google.com/file/d/1QNcqGwxQigMmccU6Cnjq5o0DcXPjNm25/view?usp=sharing" target="_blank" className='resume-dwnld-hero'><img src={Download} alt="Resume of Harsh Kothari" className="ri   img-fluid"></img><p className='ml-2 resume-link'>My resume </p></a>  
                                
                            {/* <div className="only-mobile">
                                <p className="para">
                               I enjoy creating user-centric, delightful, and human experiences; all with a minimal design.
                                </p>
                            </div> */}
                           
                        {/* <h6 className="cta">About Me</h6> */}
                        <div className='cta-btn-grp'>
                        <Link
                        activeClass = "active"
                        to="aboutMeArea"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                    ><div><Button className="box-btn mt-3">More About Me</Button></div></Link>
                            <div className="social-btn-hero-grp mt-3">
                                <a href="https://www.linkedin.com/in/harsh-kothari-b1820767" target="_blank"><img src={LinkedIN} alt="LinkedIN account of Harsh Kothari" className="ih img-fluid"></img></a>
                                <a href="mailto:harshkothari17@gmail.com" target="_blank"><img src={Mail} alt="Email of Harsh Kothari" className="ih img-fluid ml-3 mr-3"></img></a>
                                <a href="https://twitter.com/_harshkothari_" target="_blank"><img src={Twitter} alt="Twitter of Harsh Kothari" className="ih img-fluid"></img></a>
                                </div>
                        </div>

                    
                    
                        {/* </Col> */}
                        {/* <Col lg={1}></Col>
                        <Col lg={4}>
                                <img src={heroImage} alt="Profile Image of Harsh Kothari" className="img-fluid heroImg"></img>
                        </Col> */}
                    </Row>
                </Container>
            <div className="scroll-down text-center">Scroll Down for some amazing Stuff <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
</svg></div>

                
            </Container>
            
            {/* <div className="heroIcon mt-3">
                                <a href="https://www.behance.net/toharsh" target="_blank"><img src={behance} alt="Behance potfolio of Harsh Kothari" className="img-fluid aboutIcon b2"></img></a>
                                <a href="https://github.com/toHarsh" target="_blank"><img src={Github} alt="Github potfolio of Harsh Kothari" className="img-fluid aboutIcon g2"></img></a>
                                <a href="https://www.linkedin.com/in/harsh-kothari-b1820767" target="_blank"><img src={LinkedIN} alt="LinkedIN account of Harsh Kothari" className="img-fluid aboutIcon l2"></img></a>


                            </div> */}
        </section>
        </div>
    )
}

export default Hero