import './App.scss';
import React, {useState, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Content from './Content.js'
import Typewriter from "typewriter-effect";
import {Container, Row, Col} from 'react-bootstrap'

function App() {
  const [loading, setLoading] = useState(false);


  useEffect(() => {
   setLoading(true)
   setTimeout(() => {
    setLoading(false)
   }, 3000);
    
  }, []);



  return (
  <div className="App">
  {
    loading ? 
    <div className="preLoadArea">
        <Container className="text-center">
        <Row className="align-items-center h-100">
        <Col>
            <div className="preLoadText">
                            <Typewriter
                            options={{loop:true,}}
                            onInit={(typewriter)=> {
                            typewriter
                        
                            .typeString("Hello...")
                            
                            .pauseFor(500)
                            .deleteAll()
                            .start()
                            .typeString("Hello...");
                            }}
            />
            </div>
        </Col>
        </Row>
        </Container>
        </div>
     :
     <Content />
  }
    </div>
  );
}

export default App;
