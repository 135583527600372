import React,{useEffect} from 'react';
import './AboutMe.css'
import behance from '../AboutMe/Behance.svg'
import Github from '../AboutMe/Github.svg'
import LinkedIN from '../AboutMe/LinkedIN.svg'
import Dribbble from '../AboutMe/Dribbble.svg'
import { Col, Container, Row, Card,CardColumns,ProgressBar, Button } from 'react-bootstrap';
import AOS from "aos";
import "aos/dist/aos.css";
import resume from './pdfs/HarshKothariResume.pdf'
import heroImage from '../Hero/heroImg.jpeg';
import heroImage2 from '../Hero/heroImage3.jpg';

function AboutMe() {
    useEffect(() => {
        AOS.init({
          duration : 500
        });
      }, []);
    const now =55;

    const progressInstance = <ProgressBar variant="success" animated now={now} label={`${now}%`}/>;
    return (
        <section id="aboutMeArea">     
            <Container>
                <Container>
                    <Row className="aboutRow">
                        
                        <Col className="aboutrightCol" lg={7}>
                            <div className="aboutDes"  >
                            I'm Harsh,<br></br>
                           
a product designer with the focus on crafting good experiences with minimal design.

<br></br><br></br>
I've always been curious about different technical fields but have always been drawn towards the creative side. UX being a multidisciplinary field, a perfect blend of design, technology, psychology, research and business was like the perfect thing which wouldn't "feel like work". Also the fact that your designs could actually make someone's life easier is the real motivation to become a UX designer.

                            </div>
                            <br></br>
                            {/* <div className="aboutDes"  >Get connected to find out more about me.</div><br></br> */}
                            <Row className='btn-row-about'>
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className="aboutMore">
                                    know more about me?
                                </div>
                                <div className="aboutIcons mt-3">
                                    {/* <a href="https://www.behance.net/toharsh" target="_blank"><img src={behance} alt="Behance potfolio of Harsh Kothari" className="img-fluid aboutIcon b1"></img></a>
                                    <a href="https://github.com/toHarsh" target="_blank"><img src={Github} alt="Github potfolio of Harsh Kothari" className="img-fluid aboutIcon g1"></img></a>
                                    <a href="https://dribbble.com/harshskothari" target="_blank"><img src={Dribbble} alt="Dribbble account of Harsh Kothari" className="img-fluid aboutIcon l2"></img></a>
                                    <a href="https://www.linkedin.com/in/harsh-kothari-b1820767" target="_blank"><img src={LinkedIN} alt="LinkedIN account of Harsh Kothari" className="img-fluid aboutIcon l1"></img></a> */}
                                 <Button href="mailto:harshkothari17@gmail.com" target="_blank" className="box-btn-second">Let's Talk</Button>

                                </div>
                                </Col>
                                <Col xl={6} lg={6} md={6} sm={12} xs={12} className="mobile-mar">
                                <div className="aboutMore">
                                    My Resume
                                </div>
                                   <Button href="https://drive.google.com/file/d/1QNcqGwxQigMmccU6Cnjq5o0DcXPjNm25/view?usp=sharing" target="_blank" className="box-btn mt-3">My Resume</Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={1}  ></Col>
                        <Col className="aboutleftCol" lg={4}  >
                        <img src={heroImage} alt="Profile Image of Harsh Kothari." className="img-fluid heroImg"></img>
                        
                        </Col>
                    </Row>
 <Row className="skillsRow">
                        <Col lg={4} md={6} sm={12} xs={12}>
                            <Card className="card h-100 mb-4" sm={12} md={12} xs={12}  data-aos-delay="100">
                                <Card.Body>
                                <Card.Title className="cardTitle">UI/UX designer</Card.Title>
                                <Card.Text className="cardText">
                                <div className="mt-4"></div>
                                    UX Design<br></br>
                                    Design Thinking Process<br></br>
                                    Visual Design<br></br>
                                    Foundational Research<br></br>
                                    Experience Mapping<br></br>
                                    Wireframing<br></br>
                                    Prototyping<br></br><br></br>
                                    <p style={{ fontWeight: 'bold' }}>Brownie skills</p>
                                    <ul>
                                <li>Attending meetings on saturday</li>
                                <li>Convincing PMs and Creative designers</li>
                                <li>Business centric == user centric</li>
                                </ul>
                                </Card.Text>
                                
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4} md={6} sm={12} xs={12} className='middle-portfoliocard'>
                         <Card className="card h-100 mb-4" sm={12} md={12} xs={12}  data-atos-delay="100">
                            <Card.Body>
                            <Card.Title className="cardTitle">Developer</Card.Title>
                            <Card.Text className="cardText">
                            <div className="mt-4"></div>
                                HTML/ CSS<br></br>
                                Java Script<br></br>
                                Bootstrap, Sematic UI<br></br>
                                Material Design<br></br>
                                React.js<br></br>
                                    {/* <div className="progress ml-3" style="width: 50%;">
                                    <div className="progress-bar" role="progressbar" style="width: 50%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">50%</div> */}
                                {/* </div> */}
                                
                                <ul>
                                <li>React-strap</li>
                                <li>JSX</li>
                                </ul>
                                MERN {progressInstance}
                                <br></br>
                                Flutter + Dart<br></br>
                                
                            </Card.Text>
                            
                            </Card.Body>
                        </Card>
                        </Col>
                        
                        <Col lg={4} md={6} sm={12} xs={12}>
                         <Card className="card h-100 mb-4" sm={12} md={12} xs={12}  data-aos-delay="300">
                                <Card.Body>
                                <Card.Title className="cardTitle">Tools and Misc</Card.Title>
                                <Card.Text className="cardText mt-4" >
                                    Figma<br></br>
                                    Framer<br></br>
                                    Adobe XD<br></br>
                                    Miro<br></br>
                                    Pixlr<br></br>
                                    
                                    VS Code<br></br>
                                    Git<br></br>
                                    Netlify<br></br>

                                </Card.Text>
                                
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </section>
    )
}

export default AboutMe