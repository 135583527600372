import React from 'react'
import './Footer.css'

function Footer() {
    return (
        <section className="footerArea">
        <div className="footerText text-center">
                    © 2024 Harsh Kothari
                </div>
                <p className='foot-para mt-3 text-center'>Tech Stack Used: ReactJS | Bootstrap | React-Router | GitHub | Netlify</p>
                {/* <br></br> */}
                {/* <div className="text-center">Checkout My Old website: <a href="https://toharsh.github.io/" target="_blank" style={{textDecoration:"underline",fontWeight:600,color:"#3d3d3a"}}>HERE</a></div> */}
        </section>
    )
        
}

export default Footer
