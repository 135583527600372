
import React, { useEffect, useState, useCallback } from 'react'
import { Container, Modal, Row,Col, Button, Card, Nav,ProgressBar, ListGroup, Tab, Tabs} from 'react-bootstrap'
import './Portfolio.css'
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  

// import projectDir from './projects.json'
import AOS from "aos";
import "aos/dist/aos.css";
import goTrek from './GoTrek.png'
import amazon from './amazon.png'
import EcoSteps from './EcoSteps.gif'
import stayUp from './stayUp.png'
import calmSleep from './calmSleep.jpg'
import clickked from './ClickkedCover.png'
import futura from './futura.jpg'
import NoBroker from './NoBroker.png'
import Tekion from './tekion.png'
import IITB from './iitb.png'
import CircleReview from './CircleReview.png'
import Calendar from './Calendar.svg'
import mediagram from './medigram.webp'
import Arrow from './arrow_port.svg'


function Portfolio() {

    const projects = [
        {
            "key":"1", 
            "name":"Tekion Corp",
            "date":"September 2022",
            "description":"Worked as a product design intern at Tekion Corp, contributing to various projects and receiving recognition for my work on the Hummer EV configurator.",
            "url":"https://toharsh.notion.site/Tekion-Corp-Harsh-Kothari-s-Internship-f35e9a54b49d45d8920dce959d24d980",
            "category":" UX/UI Design",
            "color":"#FDEFE3",
            "color_text":"#FC8A2A",
            "type_color":"#E8D1FF",
            "type_text":"#962FFC",
            "type":"Internship",
            "img":Tekion,
            "alt":"Tekion Corp - Harsh Kothari 's Internship"
        },
        ,{
            "key":"2", 
            "name":"Cohesive By Unacademy",
            "date":"March 2023",
            "description":"As a Product Design intern at Cohesive, I led design for CircleReview, including research, UX/UI, logo design, and goal setting, delivering a SaaS products within 5 days.",
            "url":"https://toharsh.notion.site/Circle-Review-866c7b45211a4203b14bca7bde523752",
            "category":" UX/UI Design",
            "color":"#FDEFE3",
            "color_text":"#FC8A2A",
            "type_color":"#E8D1FF",
            "type_text":"#962FFC",
            "type":"Internship",
            "img":CircleReview,
            "alt":"Cohesive By Unacademy - Harsh Kothari 's Internship"
        },
        {
            "key":"3", 
            "name":"NoBroker.com",
            "date":"January 2022",
            "description":"Implemented design thinking approach to create user centric designs for to-be launched features of NoBrokerHood app.",
            "url":"https://toharsh.notion.site/NoBroker-Harsh-Kothari-6f4773f22b2a42eda6a96f5aa47a3ede",
            "category":" UX/UI Design",
            "color":"#FDEFE3",
            "color_text":"#FC8A2A",
            "type_color":"#E8D1FF",
            "type_text":"#962FFC",
            "type":"Internship",
            "img":NoBroker,
            "alt":"NoBroker - Harsh Kothari 's Internship"
        },
        {
            "key":"4",
            "name":"Indian Institute of Technology - Bombay",
            "date":"September 2021 - March 2022",
            "description":"I interned at IIT Bombay as a UX Intern under Prof. Ganesh Ramakrishnan for an AI-based edu-tech product and worked on a cross-functional team to create PRD.",
            "url":"https://drive.google.com/file/d/19VRVGTV6FRUsqoL0ZHlKQAR_vrpA7zpM/view?usp=sharing",
            // "category":"UX/UI Design",
            // "color":"#FDEFE3",
            // "color_text":"#FC8A2A",
            // "type_color":"#DCFFD9",
            // "type_text":"#2AC31D",
            // "type":"Research",
            "category":" UX/UI Design",
            "color":"#FDEFE3",
            "color_text":"#FC8A2A",
            "type_color":"#E8D1FF",
            "type_text":"#962FFC",
            "type":"Internship",
            "img":IITB,
            "alt":"EcoSteps - Project by harshkothari"
        },
        {
            "key":"5",
            "name":"Heuristic Evaluation - Amazon App",
            "description":"Tried to improve the experience of Amazon App for some users.",
            "url":"https://www.behance.net/gallery/125236813/Heuristic-Evaluation-Amazon-App",
            "category":"Heauristic Evaluation",
            "color":"#FDE3E3",
            "color_text":"#F23333",
            "type_color":"#DCFFD9",
            "type_text":"#2AC31D",
            "type":"Research",
            "img":amazon,
            "alt":"Amazon App - Heuristic Evaluation by Harsh Kothari"
        },
        {
            "key":"6",
            "name":"GoTrek - Trekking Just became easy.",
            "description":"App that Simplifies the process of searching, booking and management of treks in India.",
            "url":"https://toharsh.notion.site/GoTrek-Trekking-just-became-easy-cce65188a6fe4cbcaa0d00fa542ebb90",
            "category":"UX/UI Design",
            "color":"#FDEFE3",
            "color_text":"#FC8A2A",
            "type_color":"#D0E5FF",
            "type_text":"#2684FF",
            "type":"Project Casestudy",
            "img":goTrek,
            "alt":"GoTrek - Case Study by Harsh Kothari"
        },
        {
            "key":"7",
            "name":"Clickked - Dating can wait, let's socialise",
            "description":"I spear-headed the full design of Clickked, an exclusive invite-only dating app, including research, prototyping, and the design system, ensuring a seamless user experience.",
            "url":"https://www.figma.com/proto/RaTUJoqTZ3HUgYjfBGYB7P/Clickked---Dating-can-wait%2C-Let's-Socialise?page-id=0%3A1&type=design&node-id=1-13&viewport=2535%2C588%2C0.13&t=znLjKhhdOhYKhXqm-1&scaling=contain&starting-point-node-id=1%3A13&mode=design",
            "category":"Product Design",
            "color":"#FDEFE3",
            "color_text":"#FC8A2A",
            "type_color":"#FCE8F3",
            "type_text":"#99154B",
            "type":"Client",
            "img":clickked,
            "alt":"futura"
        },
        {
            "key":"8",
            "name":"Mediagram - A Performance Marketing Agency",
            "description":"Designed a framer website for the Indian performance marketing agency. Infused a few micro-interactions, go and explore the menu in the website.",
            "url":"https://themediagram.com/",
            "category":"Framer Design & Development",
            "color":"#DBF1FD",
            "color_text":"#4C44F5",
            "type_color":"#FCE8F3",
            "type_text":"#99154B",
            "type":"Client",
            "img":mediagram,
            "alt":"futura"
        },

        {
            "key":"9",
            "name":"Designing CalmSleep’s Onboarding Experience - A UX Case Study",
            "description":"This case study aims to convey CalmSleep's value, enhancing user comprehension of its sleep benefits, leading to increased engagement, conversions, and decreased drop-off rates.",
            "url":"https://toharsh.notion.site/Designing-CalmSleep-s-Onboarding-Experience-A-UX-Case-Study-9f2c6111a305445cab5de70d1436b2ae?pvs=4",
            "category":"Product Design",
            "color":"#FDEFE3",
            "color_text":"#FC8A2A",
            "type_color":"#D0E5FF",
            "type_text":"#2684FF",
            "type":"Client",
            "img":calmSleep,
            "alt":"CalmSleep"
        },
        {
            "key":"10",
            "name":"F.U.T.U.R.A - Walk of time",
            "description":"In 3080, time travel is as simple as canceling Goa plans, with an app enabling 50-year leaps to past or future, invisibly exploring for 10 mins, preserving reality by avoiding touch or movement",
            "url":"https://youtu.be/ej47Tzpdqf4",
            "category":"Product Design",
            "color":"#FDEFE3",
            "color_text":"#FC8A2A",
            "type_color":"#D0E5FF",
            "type_text":"#2684FF",
            "type":"Passion Casestudy",
            "img":futura,
            "alt":"futura"
        },
        // {
        //     "key":"6",
        //     "name":"StayUp - Exams are now easy.",
        //     "description":"Helping students to easily understand their performance analytics in exams, online test series, etc.",
        //     "url":"https://toharsh.notion.site/Stay-Up-Exams-are-now-easy-ea36c49730524fc794ee0d8a5af12ddd",
        //     "category":"UX/UI Design",
        //     "color":"#FDEFE3",
        //     "color_text":"#FC8A2A",
        //     "type_color":"#D0E5FF",
        //     "type_text":"#2684FF",
        //     "type":"Project Casestudy",
        //     "img":stayUp,
        //     "alt":"StayUp - Case Study by Harsh Kothari"
        // }
        
    ]
    
    useEffect(() => {
        AOS.init({
          duration : 500
        });
      }, []);

    const now =45;

    const progressInstance = <ProgressBar variant="success" animated now={now} label={`${now}%`}/>;

    // for (let i = 0; i < projects.length; i++) {
    //     var colorCatStyle = {
    //         backgroundColor: projects[i].color,
    //         // color: "white" + "!important",
    //         borderRadius: "4" + "px",
    //         textAlign: "center",
    //         width: "auto",
    //         paddingTop: "0.2"+"rem",
    //         paddingRight: "0.5"+"rem",            
    //         paddingBottom: "0.2"+"rem",
    //         paddingLeft: "0.5"+"rem", 
    //     }
    // }


    const [blogs, setBlogs] = useState([]);
    
    useEffect(() => {
        fetch('https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@toharshk')
          .then(resp => resp.json())
          .then(data => {
            setBlogs(data.items)
          })
      }, [])
    
    //   console.log(blogs)

        return (


            <section className="portfolioArea" id="potfolioArea"> 
                <Container>
                <Container>

                {/* Projects */}
                <Row className="projects">
                    <Col lg={6}>
                        {/* <h2 className="portfolioHeader" data-aos="fade-up" >Portfolio</h2> */}
                    </Col>
                </Row>
                <Row  className="projects-des">
                    <Col>
                        <h3 className="contactHeader" >My Portfolio</h3>
                        <div className="topSubheadert" >
                        Here are some of the works from my portfolio.
                     </div>
                    </Col>
                </Row>
                
                <Row className="project-display">
                    
                <Tab.Container id="left-tabs-example" defaultActiveKey="internships">
      <Row>
        <Col sm={12} xs={12} lg={3}>
          <Nav variant="pills" className="flex-column">
            <Nav.Item>
              <Nav.Link eventKey="internships" className='tab-port'>Internships <span className={'active'?'':'display-p'}><img src={Arrow}></img></span></Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="clients" className='tab-port'>Clients - Consultancy <span className={'active'?'':' active display-p'}><img src={Arrow}></img></span></Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="projects" className='tab-port'>Interesting Casestudies <span className={'active'?'':'display-p'}><img src={Arrow}></img></span></Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col sm={12} xs={12} lg={9} className='port-content'>
          <Tab.Content>
            <Tab.Pane eventKey="internships">
                <Row>
                    {projects.map((project, index) => (
                        <Col index={project.index} lg={6} md={12} sm={12} xs={12} className={project.type==="Internship"? "text-left portfolio-card" : "display-p"}>
                            <a target="_blank" href={project.url} className="portfolio-card-text-deco">
                            <div className='project-card'>
                            
                            <img alt={project.alt} className="img-fluid portfolio-proj" 
                            src={`${project.img}`}></img>
                            
                            <div className="project-card-text">
                               {project.date && (<div className='project-date-div'><img src={Calendar}></img> <div className='project-date'>{project.date}</div></div>)}
                            <h4 className="project-card-title">{project.name}</h4>
                            <p className="project-card-desc">
                            {project.description}
                            </p>
                            {/* <div className='h-100'></div> */}
                            <div className="cat-type mt-4">
                            <div className="cat">
                                <div className="cat-proj" style={{
                                    backgroundColor: projects[index].color,
                                    color: projects[index].color_text,
                                    borderRadius: "4" + "px",
                                    textAlign: "center",
                                    width: "auto",
                                    paddingTop: "0.2"+"rem",
                                    paddingRight: "0.5"+"rem",            
                                    paddingBottom: "0.2"+"rem",
                                    paddingLeft: "0.5"+"rem", 
                                    marginRight: "0.5"+"rem",
                                }}>{project.category}</div>
                            </div>
                            <div className="cat">
                                <div className="cat-proj" style={{
                                    backgroundColor: projects[index].type_color,
                                    color: projects[index].type_text,
                                    borderRadius: "4" + "px",
                                    textAlign: "center",
                                    width: "auto",
                                    paddingTop: "0.2"+"rem",
                                    paddingRight: "0.5"+"rem",            
                                    paddingBottom: "0.2"+"rem",
                                    paddingLeft: "0.5"+"rem", 
                                }}>{project.type}</div>
                            </div>
                            </div>
                           
                            </div>

                            

                            </div></a>
                            

                        </Col>
                    ))}
                    </Row></Tab.Pane>
            <Tab.Pane eventKey="clients"><Row>
                    {projects.map((project, index) => (
                        <Col index={project.index} lg={6} md={12} sm={12} xs={12} className={project.type==="Client"? "text-left portfolio-card" : "display-p"}>
                            <a target="_blank" href={project.url} className="portfolio-card-text-deco">
                            <div className='project-card'>
                            
                            <img alt={project.alt} className="img-fluid portfolio-proj" 
                            src={`${project.img}`}></img>
                            
                            <div className="project-card-text">
                               {project.date && (<div className='project-date-div'><img src={Calendar}></img> <div className='project-date'>{project.date}</div></div>)}
                            <h4 className="project-card-title">{project.name}</h4>
                            <p className="project-card-desc">
                            {project.description}
                            </p>
                            {/* <div className='h-100'></div> */}
                            <div className="cat-type mt-4">
                            <div className="cat">
                                <div className="cat-proj" style={{
                                    backgroundColor: projects[index].color,
                                    color: projects[index].color_text,
                                    borderRadius: "4" + "px",
                                    textAlign: "center",
                                    width: "auto",
                                    paddingTop: "0.2"+"rem",
                                    paddingRight: "0.5"+"rem",            
                                    paddingBottom: "0.2"+"rem",
                                    paddingLeft: "0.5"+"rem", 
                                    marginRight: "0.5"+"rem",
                                }}>{project.category}</div>
                            </div>
                            <div className="cat">
                                <div className="cat-proj" style={{
                                    backgroundColor: projects[index].type_color,
                                    color: projects[index].type_text,
                                    borderRadius: "4" + "px",
                                    textAlign: "center",
                                    width: "auto",
                                    paddingTop: "0.2"+"rem",
                                    paddingRight: "0.5"+"rem",            
                                    paddingBottom: "0.2"+"rem",
                                    paddingLeft: "0.5"+"rem", 
                                }}>{project.type}</div>
                            </div>
                            </div>
                           
                            </div>

                            

                            </div></a>
                            

                        </Col>
                    ))}
                    </Row></Tab.Pane>
            <Tab.Pane eventKey="projects">
            <Row>
                    {projects.map((project, index) => (
                        <Col index={project.index} lg={6} md={12} sm={12} xs={12} className={project.type === "Research" || project.type === "Passion Casestudy" || project.type === "Project Casestudy" ? "text-left portfolio-card" : "display-p"}
                        >
                            <a target="_blank" href={project.url} className="portfolio-card-text-deco">
                            <div className='project-card'>
                            
                            <img alt={project.alt} className="img-fluid portfolio-proj" 
                            src={`${project.img}`}></img>
                            
                            <div className="project-card-text">
                               {project.date && (<div className='project-date-div'><img src={Calendar}></img> <div className='project-date'>{project.date}</div></div>)}
                            <h4 className="project-card-title">{project.name}</h4>
                            <p className="project-card-desc">
                            {project.description}
                            </p>
                            {/* <div className='h-100'></div> */}
                            <div className="cat-type mt-4">
                            <div className="cat">
                                <div className="cat-proj" style={{
                                    backgroundColor: projects[index].color,
                                    color: projects[index].color_text,
                                    borderRadius: "4" + "px",
                                    textAlign: "center",
                                    width: "auto",
                                    paddingTop: "0.2"+"rem",
                                    paddingRight: "0.5"+"rem",            
                                    paddingBottom: "0.2"+"rem",
                                    paddingLeft: "0.5"+"rem", 
                                    marginRight: "0.5"+"rem",
                                }}>{project.category}</div>
                            </div>
                            <div className="cat">
                                <div className="cat-proj" style={{
                                    backgroundColor: projects[index].type_color,
                                    color: projects[index].type_text,
                                    borderRadius: "4" + "px",
                                    textAlign: "center",
                                    width: "auto",
                                    paddingTop: "0.2"+"rem",
                                    paddingRight: "0.5"+"rem",            
                                    paddingBottom: "0.2"+"rem",
                                    paddingLeft: "0.5"+"rem", 
                                }}>{project.type}</div>
                            </div>
                            </div>
                           
                            </div>

                            

                            </div></a>
                            

                        </Col>
                    ))}
                    </Row>
                    </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>




               
                
                    {/* <div className="project-content eco-steps">
                        
                        
                        <h3 className="project-content-header"></h3>
                        <a target="_blank" href="https://youtu.be/icoZZltAKJk">
                        <img alt="Eco Steps by Harsh Kothari" className="img-fluid eco-steps-img portfolio-proj" 
                        src={EcoSteps}></img></a>

                            <div className="cat-2">
                                <div className="project-cat-5">Visual Design</div>
                            </div>

                        
                    </div> */}
                    
                </Row>
                {/* Projects */}



                   
                </Container>
                </Container>

                <Container>
                    <div className="contactHeader margin-t-o">My Publications</div>
                    <div className="topSubheadert" >
                        Published in <a href="https://uxdesign.cc/" target="_blank" className='pub_link'>UX Collective</a>, <a className='pub_link' href="https://bootcamp.uxdesign.cc/" target="_blank">Bootcamp</a>, etc.
                     </div>
                    <div className='other-case-study-section'>
                        <Row>
                        {blogs.map(blog => {
                                    return (
                                        <Col index={blog.index} lg={6} md={6} sm={12} xs={12} className="text-left portfolio-card">
                            
                            <div className='project-card'>
                            <a target="_blank" href={blog.link}>
                            <img alt={blog.title} className="img-fluid portfolio-proj" 
                            src={blog.thumbnail}></img></a>
                            
                            <div className="project-card-text">
                            <h4 className="project-card-title">{blog.title}</h4>
                            {/* <p className="project-card-desc">
                            {project.description}
                            </p> */}
                            
                           
                            </div>

                            

                            </div>
                            

                        </Col>
                                        )
                                })}
                        </Row>
                    </div>
                </Container>
                
            </section>
        
        //     <section className="portfolioArea" id="potfolioArea"> 
            
            
        //     <Modal show={show} onHide={handleClose}>
        //     <Modal.Header closeButton>
        //       <Modal.Title>UX Projects</Modal.Title>
        //     </Modal.Header>
        //     <Modal.Body>
        //         <Row>
                    
        //             {uip.uiproject.map((uiprojects)=>(
        //                 <Col xl sm={6} xs={6} md lg={6}>
        //                         <div><a href="https://www.behance.net/toharsh" target="_blank"><img alt="uiprojects Designs by Harsh Kothari."  className="ModallItem" src= {uiprojects.url}/></a></div>  
        //                         </Col>
        //                     ))}
                    
        //         </Row>
           
        //     </Modal.Body>
        //     <Modal.Footer>
        //       <a onClick={handleClose}><h6 className="portfoliocta">Close</h6></a>
        //     </Modal.Footer>
        //   </Modal>


        //         <Container>
        //         <Row className="heroRow">
        //             <Col className="leftCol" lg={4} md={12} xs={12} sm={12} data-aos="fade-up">
        //                 <h2 className="portfolioHeader">UX/UI</h2>
        //                 <h6 className="portfoliodes">Works and Projects</h6>
        //                {/* <a onClick={handleShow}><h6 className="portfoliocta">View more</h6></a> */}
        //             </Col>

        //             <Col className="portfoliorightCol" lg={8} md={12} xs={12} sm={12} data-aos="fade-up" data-atos-delay="100">
        //             {/* <Row>
                    // {uip.uiproject.map((uiprojects)=>(
                    //     <Col xl sm={6} xs={6} md lg={6} xl={6}>
                    //             <div><a key={uiprojects.id} href="https://www.behance.net/toharsh" target="_blank"><img alt="uiprojects Designs by Harsh Kothari." className="uiProtItem" src= {uiprojects.url}/></a></div>  
                    //             </Col>
                    //         ))}</Row> */}
        //                     <Row>
        //                         {/* <Col><div className="portfoliodes">Designs I've created</div></Col> */}
        //                         {/* <div className="mx-auto"></div> */}
        //                         <Col><a onClick={handleShow}><h6 className="portfoliocta">View more visual designs</h6></a></Col>
        //                     </Row>
                            
                             
                        
        //                 <OwlCarousel items={2}  
        //                     className="owl-theme"  
        //                     loop   
        //                     margin={8} >  
                            
        //                     <div><a href="https://www.behance.net/toharsh" target="_blank"><img alt="UI Designs by Harsh Kothari."  className="carouselItem" src={ui_2}/></a></div>  
        //                     <div><a href="https://www.behance.net/toharsh" target="_blank"><img alt="UI Designs by Harsh Kothari."  className="carouselItem" src={ui_1}/></a></div>  
        //                     <div><a href="https://www.behance.net/toharsh" target="_blank"><img alt="UI Designs by Harsh Kothari."  className="carouselItem" src={ui_7}/></a></div>  

        //                     {/* <div><a href="https://www.behance.net/toharsh" target="_blank"><img alt="UI Designs by Harsh Kothari."  className="carouselItem" src={ui_3}/></a></div>  
        //                     <div><a href="https://www.behance.net/toharsh" target="_blank"><img alt="UI Designs by Harsh Kothari."  className="carouselItem" src={ui_4}/></a></div>  
        //                     <div><a href="https://www.behance.net/toharsh" target="_blank"><img alt="UI Designs by Harsh Kothari."  className="carouselItem" src={ui_5}/></a></div>  
        //                     <div><a href="https://www.behance.net/toharsh" target="_blank"><img alt="UI Designs by Harsh Kothari."  className="carouselItem" src={ui_6}/></a>  </div>  
        //                      */}
                           

                           
        //                 </OwlCarousel>  

        //                 <Row className="mb-2 prow">
        //                     {/* <Col>
        //                         <div className="portfoliodes">Figma Community Files</div> */}
        //                         {/* <p className="des">Click on the image to duplicate the figma file.</p>
        //                     </Col>
        //                      */}
        //                     <Col>
        //                         {/* <div className="mx-auto"></div> */}
        //                         <a href="#"><h6 className="portfoliocta">More figma community files...</h6></a>
        //                     </Col>
        //                 </Row>
        //                 <OwlCarousel items={1}  
        //                     className="owl-theme"  
                               
        //                     margin={8} >                              
        //                     {/* <div><a href="https://www.behance.net/toharsh" target="_blank"><img alt="UI Designs by Harsh Kothari."  className="carouselItem" src={ui_2}/></a></div>   */}
        //                     <div><a href="https://www.figma.com/community/file/967338566341825957/ESCLUSIVA" target="_blank"><img alt="UI Designs by Harsh Kothari."  className="figmalItem" src={ui_7}/></a></div>  
        //                 </OwlCarousel>  


        //                 <Row className="mb-2 prow">
        //                     <Col>
        //                         {/* <div className="portfoliodes">Blogs on UX</div> */}
        //                         {/* <p className="des">Click on the image to duplicate the figma file.</p> */}
                            
        //                     </Col>
                                
        //                         {/* <div className="mx-auto"></div> */}
        //                         <Col><a href="https://blog.webscale.in/author/harsh-kothari/" target="_blank"><h6 className="portfoliocta">View more blogs</h6></a></Col>
        //                 </Row>
        //                 <OwlCarousel items={2}  
        //                     className="owl-theme"  
        //                     loop
        //                     margin={8} >                              
        //                     {/* <div><a href="https://www.behance.net/toharsh" target="_blank"><img alt="UI Designs by Harsh Kothari."  className="carouselItem" src={ui_2}/></a></div>   */}
        //                     <div><a href="https://blog.webscale.in/uxs-effect-on-a-business/" target="_blank"><img alt="UX Blogs by Harsh Kothari."  className="figmalItem" src={ux_blog_1}/></a></div>  
        //                     <div><a href="https://blog.webscale.in/ux-effect-on-a-business/" target="_blank"><img alt="UX Blogs by Harsh Kothari."  className="figmalItem" src={ux_blog_2}/></a></div>  

        //                 </OwlCarousel>  

                        

        //                 <Row>
        //                     <Col>
        //                         <h6 className="csdes">Case Studies/ Heuristic Evaluations/ Redesign Report/ Usability Tests....Coming Soon</h6>
        //                     </Col>
        //                 </Row>
        //                 </Col>
        //             </Row>

        //             <Row data-aos="fade-up" className="ucrow">
        //                 <Col>
        //                     <img src={uc} className="img-fluid ucI" alt="this part of harshkothari.tech is under construction"></img>
        //                     {/* <div className="ucheader">This Part is Under Construction</div> */}
        //                     <h6 className="ucdes">This part is under construction & will contain the <span style={{textDecoration:"underline",color:"#3d3d3a"}}>Web and App development</span> projects.</h6>

        //                 </Col>
        //             </Row>
               
        //         </Container>
        //     </section>
    )
}

export default Portfolio